import React from 'react';

export default function AgentFlows() {
  return (
    <div className="w-full h-full">
      <div className="w-full h-full pl-[100px]">
        <div className="w-full h-full relative">
          <iframe
            src="https://flowise-service-649264979681.us-central1.run.app/agentflows"
            // src="http://34.121.176.86/agentflows"
            style={{
              width: 'calc(100% - 100px)', // Dynamically adjust width based on the sidebar
              height: '100vh',
              border: 'none',
              position: 'fixed',
              top: 0,
              left: '100px', // Account for sidebar width
              backgroundColor: 'white',
            }}
            title="AgentFlows"
          />
        </div>
      </div>
    </div>
  );
}