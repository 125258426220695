import { Flex, Icon, Tooltip as CTooltip, Button, Box } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
// import { PieChart, Pie, Sector, ResponsiveContainer } from "recharts";
import { GiUpgrade } from "react-icons/gi";
import { PieChart } from "react-minimal-pie-chart";
import { BsInfoSquareFill } from "react-icons/bs";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  Tooltip,
} from "recharts";
import AuthTokenService from "../../utils/AuthTokenService";
import { Topbar } from "../../components/shell";
import { getWidgetDetails } from "../../store/thunk/dashboardThunk";
import { useDispatch } from "react-redux";
import { getTaskLogsList } from "../../store/thunk/historyThunk";
import InfiniteScroll from "react-infinite-scroll-component";
import { getKonnectsList } from "../../store/thunk/dashflowThunk";
import { getFlow } from "../../store/thunk/flowThunk";
import { useNavigate } from "react-router-dom";
import { RiFileEditLine } from "react-icons/ri";

export const Dashboard = () => {
  return (
    <>
      <Topbar heading="DASHBOARD" />
      <Flex
        sx={{
          width: "100%",
          height: "100%",
          overflowY: "auto",
          overflowX: "hidden",
          flexDir: "column",
          gap: "40px",
          pb: "5px",
        }}
      >
        <Flex width="100%" gap="40px">
          {[1, 2, 3].map((ele, i) => {
            return i == 0 ? (
              <TotalFlow key={i} />
            ) : i == 1 ? (
              <ExtraTask key={i} />
            ) : (
              <UpradeAccount key={i} />
            );
          })}
        </Flex>
        <Flex width="100%" gap="40px">
          <HistoryChart />
          <TaskOverview />
        </Flex>
      </Flex>
    </>
  );
};

const TotalFlow = () => {
  const [flowData, setFlowData] = useState({
    total_konnects: "",
    active_konnects: "",
    inactive_konnects: "",
  });
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getWidgetDetails("total_konnectz")).then((res) => {
      if (res.error) {
        dispatch(setRoute("Dashboard"));
        AuthTokenService.clear();
        navigate("/login");
      } else setFlowData(res.payload);
    });
  }, []);
  return (
    <Flex
      width={1 / 3}
      height="180px"
      sx={{
        bgColor: "#fff",
        borderRadius: "8px",
        boxShadow: "md",
        p: "20px",
        gap: "15px",
        flexDir: "column",
      }}
    >
      <Flex sx={{ fontSize: 18, fontWeight: 600 }}>Total Flows</Flex>
      <Flex sx={{ flexDir: "column" }}>
        {/* <Flex sx={{ fontSize: "10px" }}>Total Flows</Flex>{" "} */}
        <Flex sx={{ fontSize: 36 }}>{flowData.total_konnects}</Flex>
      </Flex>
      <Flex gap="20px">
        <Flex sx={{ alignItems: "flex-end" }}>
          Active Flow:{" "}
          <Flex sx={{ pl: "4px", fontSize: 20, alignItems: "flex-end" }}>
            {flowData.active_konnects}
          </Flex>
        </Flex>
        <Flex sx={{ alignItems: "flex-end" }}>
          Inactive Flow:{" "}
          <Flex sx={{ pl: "4px", fontSize: 20, alignItems: "flex-end" }}>
            {flowData.inactive_konnects}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

const ExtraTask = () => {
  return (
    <Flex
      width={1 / 3}
      height="180px"
      sx={{
        bgColor: "#fff",
        boxShadow: "md",
        borderRadius: "8px",
        p: "20px",
        gap: "15px",
        flexDir: "column",
      }}
    >
      <Flex sx={{ fontSize: 18, fontWeight: 600 }}>Extra Tasks</Flex>
      <Flex sx={{ flexDir: "column" }}>
        {/* <Flex sx={{ fontSize: "10px" }}>Total Flows</Flex>{" "} */}
        <Flex sx={{ fontSize: 36 }}>0</Flex>
      </Flex>
      <Flex gap="20px">
        <Flex sx={{ alignItems: "flex-end" }}>
          Consumed Task:{" "}
          <Flex sx={{ pl: "4px", fontSize: 20, alignItems: "flex-end" }}>
            0
          </Flex>
        </Flex>
        <Flex sx={{ alignItems: "flex-end" }}>
          Remaining Task:{" "}
          <Flex sx={{ pl: "4px", fontSize: 20, alignItems: "flex-end" }}>
            0
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

const UpradeAccount = () => {
  return (
    <Flex
      width={1 / 3}
      height="180px"
      sx={{
        bgColor: "#fff",
        borderRadius: "8px",
        p: "20px",
        gap: "20px",
        boxShadow: "md",
        justifyContent: "space-between",
        flexDir: "column",
      }}
    >
      <Flex sx={{ flexDir: "column", gap: "10px" }}>
        <Flex sx={{ fontSize: 18, fontWeight: 800 }}>Upgrade to Premium</Flex>
        <Flex sx={{ fontSize: 14 }}>
          Only $99/Year, you can manage like a pro.
        </Flex>
      </Flex>

      <Flex
        sx={{
          gap: "10px",
          alignItems: "center",
          cursor: "pointer",
          fontSize: 20,
          borderTopRightRadius: "14px",
          borderBottomRightRadius: "14px",
          borderTopLeftRadius: "22px",
          borderBottomLeftRadius: "22px",
          bgColor: "#dce2e5",
          height: "44px",
        }}
      >
        <Flex
          sx={{
            borderRadius: "50%",
            bgColor: "#32445b",
            width: "48px",
            height: "48px",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Icon
            as={GiUpgrade}
            boxSize="24px"
            id="icon"
            sx={{
              color: "#fff",
            }}
          />
        </Flex>
        Upgrade Account
      </Flex>
    </Flex>
  );
};

const TaskOverview = () => {
  const [flowData, setFlowData] = useState({
    task_limit: "",
    task_consumed: "",
    task_available: "",
  });
  const [data, setData] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getWidgetDetails("task_consumption")).then((res) => {
      if (res.error) {
      } else {
        setFlowData(res.payload);
        setData([
          {
            title: "Task Consumed",
            value: res.payload.task_consumed,
            // color: "#84A7A1",
            color: "#0E2954",
          },
          {
            title: "Task Available",
            value: res.payload.task_available,
            color: "#2E8A99",
          },
        ]);
      }
    });
  }, []);
  return (
    <Flex
      width={1 / 3}
      sx={{
        bgColor: "#fff",
        alignItems: "center",
        justifyContent: "center",
        flexDir: "column",
        boxShadow: "md",
        borderRadius: "8px",
        px: "10px",
        py: "20px",
        gap: "20px",
      }}
    >
      <Flex sx={{ fontSize: 18, fontWeight: 600 }}>Tasks Overview</Flex>
      <Flex sx={{ m: "0px auto" }}>
        <PieChart
          data={data}
          lineWidth={20}
          paddingAngle={18}
          rounded
          label={({ dataEntry }) => dataEntry.value}
          labelStyle={(index) => ({
            fill: data[index].color,
            fontSize: "5px",
            fontFamily: "sans-serif",
          })}
          labelPosition={60}
        />
      </Flex>
      <Flex
        sx={{
          flexDir: "column",
          width: "100%",
          justifyContent: "center",
          gap: "20px",
        }}
      >
        <Flex width="100%">
          <Flex width="50%" sx={{ flexDir: "column", alignItems: "center" }}>
            <Flex sx={{ fontSize: 26, fontWeight: 800 }}>
              {flowData.task_consumed}
            </Flex>
            <Flex sx={{ fontSize: 12 }}>Task Consumed</Flex>
          </Flex>
          <Flex width="50%" sx={{ flexDir: "column", alignItems: "center" }}>
            <Flex sx={{ fontSize: 26, fontWeight: 800 }}>
              {flowData.task_available}
            </Flex>
            <Flex sx={{ fontSize: 12 }}>Task Available</Flex>
          </Flex>
        </Flex>
        <Flex sx={{ flexDir: "column", alignItems: "center" }}>
          <Flex sx={{ fontSize: 26, fontWeight: 800 }}>
            {flowData.task_limit}
          </Flex>
          <Flex sx={{ fontSize: 12 }}>Total Task Limit</Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

const HistoryChart = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  useEffect(() => {
    dispatch(getKonnectsList({ page_size: 15, include_created_at: true })).then(
      (response) => {
        if (response.error) {
        } else {
          const result = response.payload.konnects
            .filter((kon, i) => {
              if (i < 6) {
                return true;
              }
            })
            .map((kon) => ({
              id: kon.id,
              name: kon.konnect_name,
              created_at: kon.created_at,
              success: kon.success_task_count,
              error: kon.error_task_count,
              status: kon.status,
              ...kon,
            }));
          setData(result);
        }
      }
    );
  }, []);
  return (
    <Flex
      width={2 / 3}
      sx={{
        bgColor: "#fff",
        // alignItems: "center",
        // justifyContent: "center",
        flexDir: "column",
        boxShadow: "md",
        borderRadius: "8px",
        p: "20px",
        gap: "20px",
      }}
    >
      <Flex
        sx={{ fontSize: 18, fontWeight: 600, justifyContent: "space-between" }}
      >
        <Flex>Recent Flows</Flex>
        <CTooltip label="Success/Error Tasks in the lastest 6 Flows are shown here. To know more, visit Flows">
          <Flex sx={{ cursor: "pointer" }}>
            <Icon
              as={BsInfoSquareFill}
              boxSize="24px"
              id="icon"
              sx={{
                color: "#32445b",
                boxShadow: "md",
              }}
            />
          </Flex>
        </CTooltip>
      </Flex>
      <Flex
        sx={{
          width: "100%",
          px: "10px",
          borderBottom: "1px solid #929DAA",
          color: "#929DAA",
        }}
      >
        <Flex width={1 / 3} sx={{ px: "5px" }}>
          Name
        </Flex>
        <Flex width={1 / 4} sx={{ px: "5px" }}>
          Created At
        </Flex>
        <Flex width={1 / 4} sx={{ px: "5px" }}>
          <Flex sx={{ justifyContent: "center", width: "90%" }}>Status</Flex>
        </Flex>
        <Flex width={1 / 3} sx={{ px: "5px" }}>
          Logs
        </Flex>
        <Flex width={1 / 9} sx={{ px: "5px" }}>
          Action
        </Flex>
      </Flex>
      <Flex position="relative" height="100%" width="100%">
        <Flex
          id="scrollableDiv1"
          sx={{
            position: "absolute",
            height: "100%",
            right: 0,
            left: 0,
            top: 0,
            bottom: 0,
            overflowY: "auto",
            flexWrap: "wrap",
            borderRadius: "2px",
            rowGap: "10px",
          }}
        >
          {" "}
          <Box sx={{ width: "100%", height: "100%" }}>
            {data.length > 0 ? (
              data.map((app, i) => {
                return (
                  <FlowView
                    app={app}
                    key={i}
                    // setFlowId={setFlowId}
                    // onOpen={onOpen}
                  />
                );
              })
            ) : (
              <Flex
                sx={{
                  width: "100%",
                  p: "20px 36px 20px 20px",
                  cursor: "pointer",
                  borderBottom: "0.5px solid #D8DEE2",
                  transition: "background 0.3s",
                }}
              >
                No recent flow found. Create a flow.
              </Flex>
            )}
          </Box>
        </Flex>
      </Flex>
    </Flex>
  );
};

const FlowView = ({ app }) => {
  const navigate = useNavigate();
  return (
    <Flex
      sx={{
        width: "100%",
        p: "20px 10px",
        alignItems: "center",
      }}
    >
      <Flex width={1 / 3} sx={{ px: "5px" }}>
        {app.name ||
          `${app.left_app.name.toLowerCase()} - ${app.right_apps
            .map((rightApp, i) => rightApp.app_name.toLowerCase())
            .join(" - ")}`}
      </Flex>
      <Flex width={1 / 4} sx={{ px: "5px" }}>
        {app.created_at
          ? app.created_at
          : `${new Date().toLocaleDateString("en-US", {
              year: "numeric",
              month: "long",
              day: "numeric",
            })} ${new Date().toLocaleTimeString("en-US", {
              hour: "2-digit",
              minute: "2-digit",
              second: "2-digit",
            })}`}
      </Flex>
      <Flex width={1 / 4} sx={{ px: "5px" }}>
        {app.status === "Draft" ? (
          <Flex
            sx={{
              bgColor: "rgb(139,179,244, 0.5)",
              borderRadius: "15px",
              color: "#6AA3F1",
              width: "90%",
              justifyContent: "center",
              py: "5px",
            }}
          >
            Draft
          </Flex>
        ) : (
          <Flex
            sx={{
              bgColor: "rgb(99,219,107,0.5)",
              borderRadius: "15px",
              color: "rgb(99,219,107)",
              width: "90%",
              justifyContent: "center",
              py: "5px",
            }}
          >
            Published
          </Flex>
        )}
      </Flex>
      <Flex width={1 / 3} sx={{ px: "5px", gap: "10px" }}>
        <Flex sx={{ gap: "5px" }}>
          Success:{" "}
          <span>{app.success_task_count ? app.success_task_count : 0}</span>
        </Flex>{" "}
        <Flex sx={{ gap: "5px" }}>
          Error: <span>{app.error_task_count ? error_task_count : 0}</span>
        </Flex>
      </Flex>
      <Flex width={1 / 9} sx={{ px: "5px", justifyContent: "center" }}>
        <CTooltip label="Edit Flow">
          <Flex
            sx={{ cursor: "pointer" }}
            onClick={() => {
              navigate(`/create/${app.id}`);
            }}
          >
            <Icon as={RiFileEditLine} boxSize="20px" id="icon" />
          </Flex>
        </CTooltip>
      </Flex>
    </Flex>
  );
};
